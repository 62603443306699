import { Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const DataAnalystHero: React.FC = () => {
  return (
    <Box
      width={{ base: "100%" }}
      maxWidth="100%"
      maxHeight="40rem"
      paddingTop="1rem"
      margin="0 auto"
    >
      <StaticImage
        layout="constrained"
        src="../../../../images/jonathan_and_julia.jpg"
        alt=""
        placeholder="blurred"
        style={{ width: "100%", height: "auto", maxHeight: "inherit" }}
      />
    </Box>
  );
};
