import React from "react";
import { Layout } from "../components/layout/Layout";
import { UiSection } from "../components/sections/careers/uiDesigner/UiSection";
import { MetaTags } from "../components/MetaTags";
import { DataAnalystHero } from "../components/sections/careers/dataAnalyst/DataAnalystHero";
import { DataAnalystSection } from "../components/sections/careers/dataAnalyst/DataAnalystSection";

const DataAnalystPage: React.FC = () => {
  return (
    <Layout
      colorScheme="secondary"
      heroSection={<DataAnalystHero />}
      isHeroShortBaseOnImage
      isWhite
    >
      <DataAnalystSection />
    </Layout>
  );
};

export default DataAnalystPage;

export const Head = () => <MetaTags title="DataAnalystPage" />;
