import React from "react";
import { Section } from "../../../layout/Section";
import { Box, Flex, Text } from "@chakra-ui/react";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleHeading from "../../../ArticleHeading";
import { BodyText } from "../../../texts/BodyText";
import ArticleImageContainer from "../../../ArticleImageContainer";
import { StaticImage } from "gatsby-plugin-image";
import HeadPara from "../../../HeadPara";
import { DotNotationContainer } from "../../../layout/dotNotation/DotNotationContainer";
import { DotNotationItem } from "../../../layout/dotNotation/DotNotationItem";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";

export interface IDataAnalystSectionProps {}

export const DataAnalystSection: React.FC<IDataAnalystSectionProps> = () => {
  return (
    <Section>
      <Flex direction="column" gap="5rem">
        <AlignmentContainer>
          <ArticleHeading subtitle="Dataanalytiker, Power BI" />
          <BodyText>
            Are you looking for an opportunity in data management and Power BI?
          </BodyText>
          <BodyText>
            We at Indevit are looking for a dedicated and skilled person for a
            temporary position, with the possibility of extension. We need you
            to strengthen our data processing strategy!
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <Box height="40rem" overflow="hidden">
            <StaticImage
              alt=""
              src="../../../../images/marica_working1.jpg"
              placeholder="none"
              layout="fullWidth"
              transformOptions={{ fit: "inside", cropFocus: "attention" }}
            />
          </Box>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="About the job" />
          <BodyText>
            We are looking for someone who has knowledge and experience of
            working with Power BI for data analysis and visualisation. You
            should be capable of working independently as well as part of a
            team, with good communication skills. A big plus is flexibility and
            the ability to adapt to changing requirements and new challenges. We
            are looking for a dedicated person for a one-year position, with a
            good possibility of extension. The work can start as soon as we find
            the right person.
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start">
          <DotNotationContainer>
            <HeadPara medium para="We are looking for you who have..." />
            <DotNotationItem text="Experience of working in Power BI or equivalent tools." />
            <DotNotationItem text="Ability to move, refine and assure data and its quality and prepare it for advanced analysis." />
            <DotNotationItem text="Comfortable working with large amounts of data using SQL." />
            <DotNotationItem text="Customer orientated and able to understand the needs of different users." />
            <DotNotationItem text="Excellent problem-solving skills and a talent for innovative thinking." />
            <DotNotationItem text="Fluent in Swedish or Finnish and English." />
          </DotNotationContainer>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start">
          <DotNotationContainer>
            <HeadPara medium para="Indevit offers..." />
            <DotNotationItem text="Competitive salary." />
            <DotNotationItem text="Flexible working hours and location (hybrid and/or in-office)." />
            <DotNotationItem text="Good work/life balance." />
            <DotNotationItem text="A great team!" />
          </DotNotationContainer>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/indevit_working.jpg"
            placeholder="none"
            style={{ maxHeight: "40rem" }}
          />
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Application form" />
          <BodyText>
            If you think you are the right person for this job, do not hesitate
            to contact us! Please send your CV and a personal letter to
            <b>marica.ost@indevit.fi</b>.
          </BodyText>
          <BodyText>
            We look forward to hearing from you and discussing the possibility
            of working together to create something amazing!
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start" marginTop="5rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};
